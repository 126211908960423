export const navItems = [
  {
    name: "Work",
    route: "/work",
  },
  {
    name: "About me",
    route: "/about-me",
  },
];
